<template>
  <div id="Users">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="item" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-btn @click="redirectToAddUser()" color="#19b4bf" dark>
            Ajouter un Utilisateur
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card class="pa-3" outlined tile>
            <v-col>
              <v-row>
                <v-col cols="6" md="3" v-if="filter === filters[2]">
                  <v-text-field
                    label="Nom d'utilisateur"
                    placeholder="Nom d'utilisateur"
                    v-model="searchUsername"
                    @input="searcheByUsername"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3" v-if="filter === filters[0]">
                  <v-text-field
                    label="Nom"
                    placeholder="Nom"
                    v-model="searchName"
                    @input="searcheByName"
                    outlined
                    @click:clear="clearfilters()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3" v-if="filter === filters[1]">
                  <v-text-field
                    label="Prénom"
                    placeholder="Prénom"
                    v-model="searchSurname"
                    @input="searchBySurname"
                    outlined
                    @click:clear="clearfilters()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6" v-if="filter === filters[3]">
                  <hotel-date-picker
                    clearable
                    style="opacity: 90%; z-index: 2"
                    :halfDay="true"
                    :displayClearButton="true"
                    :i18n="frFR"
                    :positionRight="true"
                    :minNights="1"
                    :format="dateFormat"
                    :showYear="true"
                    :showCloseButton="false"
                    :startDate="new Date(new Date().getFullYear(), 0, 1)"
                    :endDate="new Date(Date.now() + 3600 * 1000 * 24)"
                    @check-in-changed="checkInChanged($event)"
                    @check-out-changed="checkOutChanged($event)"
                    @clear-selection="clear($event)"
                  />
                </v-col>

                <v-col cols="6" md="3">
                  <v-select
                    :items="items"
                    clearable
                    hide-details
                    hide-selected
                    placeholder="Type de compte"
                    label="Type de compte"
                    v-model="TypeCompte"
                    item-value="TypeCompte"
                    @input="onTypesDeCompteChange"
                  ></v-select>
                </v-col>

                <v-col cols="6" md="3">
                  <v-select
                    ref="theSelect"
                    placeholder="Filters"
                    hide-selected
                    light
                    @change="clearfilters()"
                    label="Filters"
                    :items="filters"
                    v-model="filter"
                    clearable
                    append-icon="mdi-filter-variant"
                    @click:append-outer="openSelect"
                  />
                </v-col>

                <!-- Filtre affiche les compte admin et editeur -->
                <v-col cols="6" md="3">
                  <v-radio-group mandatory>
                    <v-radio
                      label="Administrateurs"
                      @change="onChangeRole('admin')"
                    ></v-radio>
                    <v-radio
                      label="Éditeurs"
                      @change="onChangeRole('editor')"
                    ></v-radio>
                    <v-radio
                      label="Utilisateurs Pro"
                      @change="onChangeRole('pro')"
                    ></v-radio>
                    <v-radio
                      label="Utilisateurs externes"
                      @change="onChangeRole('guest')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <v-card>
            <v-card-title> Utilisateurs </v-card-title>
            <v-data-table
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 30],
              }"
              :headers="headers"
              :items="clients"
              :search="search"
              :server-items-length="totalClientsCount"
              :options.sync="options"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:item="row">
                <tr>
                  <td class="d-block d-sm-table-cell">
                    <v-btn @click="redirectToEditClient(row.item)" text>
                      <span class="mr-2">{{ row.item.userId }}</span>
                    </v-btn>
                  </td>
                  <td class="d-block d-sm-table-cell">
                    {{ row.item.username }}
                  </td>
                  <td class="d-block d-sm-table-cell">{{ row.item.name }}</td>
                  <td class="d-block d-sm-table-cell">
                    {{ row.item.surname }}
                  </td>
                  <td class="d-block d-sm-table-cell">{{ row.item.email }}</td>
                  <td class="d-block d-sm-table-cell">
                    <v-row>
                      <v-col v-if="row.item.TypeDeCompte === true">
                        <v-chip class="ma-2" color="green" text-color="white">
                          Compte Professionnel
                        </v-chip>
                      </v-col>
                      <v-col v-if="row.item.TypeDeCompte === false">
                        <v-chip class="ma-2" color="blue" text-color="white">
                          Compte Particulier
                        </v-chip>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="d-block d-sm-table-cell">{{ row.item.date }}</td>
                  <td class="d-block d-sm-table-cell">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="DeleteItem(row)"
                    >
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                    <v-dialog
                      v-model="DialogDelete"
                      :retain-focus="false"
                      max-width="500px"
                      content-class="elevation-2"
                      overlay-opacity="0.2"
                    >
                      <v-card>
                        <v-card-title class="font-weight-medium">
                          Voulez-vous supprimer cet utilisateur ?
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDelete()"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="error"
                            text
                            @click="deleteItemConfirm(row)"
                          >
                            OK
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import nodesFieldName from "../../utils/nodesFieldName";
import FirebaseDB from "../../utils/firebaseDB";
import FunctionsUtils from "../../utils/functions";
import moment from "moment";
import HotelDatePicker from "vue-hotel-datepicker2";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
import firebase from "firebase";

export default {
  name: "Users",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    HotelDatePicker,
  },

  data() {
    return {
      DialogDelete: false,
      frFR: {
        night: "jour",
        nights: "jours",
        "check-in": "",
        "check-out": "Date d'inscription",
        "day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],

        tooltip: {
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
        },
      },
      fromDate: new Date("2021-01-01"),
      toDate: new Date(Date.now() + 3600 * 1000 * 24),

      item: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      items: ["Professionnel", "Particulier"],
      itemsRole: ["Admin", "Editeur", "Externe"],
      filter: null,
      filters: [
        "Filtre par Nom ",
        "Filtre par Prénom ",
        "Filtre par Nom d'utilisateur",
        "Filtre par date d'inscription ",
      ],
      menu: false,
      modalTo: false,
      modalFrom: false,
      clients: [],
      totalClients: [],
      search: "",
      options: {},
      name: null,
      searchName: null,
      TypeCompte: null,
      surname: null,
      searchSurname: null,
      phoneNumber: null,
      email: null,
      totalClientsCount: 0,
      loading: true,
      lastVisibleClients: null,
      userId: null,
      date: null,
      dates: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      from: null,
      to: null,
      Compte: null,
      userName: null,
      searchUsername: null,
      addresses: [],
      dateRange: false,
      compte: "admin",
      deletedBy: null,
      deletedById: null,
    };
  },

  methods: {
    //-----------supprimer un utilisateur (deleted = true) en base de données------------------------------//
    DeleteItem(row) {
      this.userId = row.item.userId;
      this.DialogDelete = true;
    },
    closeDelete() {
      this.DialogDelete = false;
      this.userId = null;
      this.deletedBy = null;
      this.deletedById = null;
    },
    deleteItemConfirm(row) {
      this.deletedBy = firebase.auth().currentUser;
      this.deletedById = this.deletedBy.uid;
      db.collection(NodesName.USERS_DETAILS)
        .doc(this.userId)
        .update({
          deleted: true,
          deletedBy: this.deletedById,
          deletedDate: new Date(),
        })
        .then(() => {
          this.userId = null;
          this.DialogDelete = false;
          this.deletedBy = null;
          this.deletedById = null;
          this.clients.splice(row.index, 1);
        });
    },
    //-----------------------------------------//
    clearfilters() {
      (this.searchSurname = null),
        (this.searchName = null),
        (this.searchUsername = null),
        (this.TypeCompte = null),
        (this.fromDate = new Date("2020-01-01"));
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.dateRange = false;
      this.loadClients();
    },
    openSelect() {
      this.$refs["theSelect"].onClick();
    },
    searcheByUsername: function () {
      if (this.searchUsername.length > 2 || this.searchUsername.length < 1) {
        this.totalClients = [];
        this.clients = [];
        this.lastVisibleClients = null;
        this.loadClients();
      }
    },
    searcheByName: function () {
      if (this.searchName.length > 2 || this.searchName.length < 1) {
        this.totalClients = [];
        this.clients = [];
        this.lastVisibleClients = null;
        this.loadClients();
      }
    },
    searchBySurname: function () {
      if (this.searchSurname.length > 2 || this.searchSurname.length < 1) {
        this.totalClients = [];
        this.clients = [];
        this.lastVisibleClients = null;
        this.loadClients();
      }
    },
    onTypesDeCompteChange: function () {
      this.totalClients = [];
      this.clients = [];
      this.lastVisibleClients = null;
      this.loadClients();
    },
    onChangeRole: function (compte) {
      this.compte = compte;
      this.totalClients = [];
      this.clients = [];
      this.lastVisibleClients = null;
      this.loadClients();
    },
    redirectToEditClient(item) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userId: item.userId,
          username: item.username,
          name: item.name,
          surname: item.surname,
          email: item.email,
          TypeDeCompte: item.TypeDeCompte,
        },
      });
    },
    clear() {
      this.fromDate = new Date("2020-01-01");
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.totalClients = [];
      this.clients = [];
      this.lastVisibleClients = null;
      this.dateRange = false;
    },
    checkInChanged(newDate) {
      this.totalClients = [];
      this.clients = [];
      this.lastVisibleClients = null;
      this.fromDate = newDate;
      this.dateRange = true;
      this.loadClients();
    },
    checkOutChanged(newDate) {
      this.totalClients = [];
      this.clients = [];
      this.lastVisibleClients = null;
      this.toDate = newDate;
      this.loadClients();
    },
    redirectToAddUser() {
      this.$router.push({
        name: "AddUser",
      });
    },

    async loadClients() {
      this.loading = true;

      if (!this.fromDate && !this.toDate) {
        this.fromDate = new Date("2021-01-01");
        this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      var query = db.collection(NodesName.USERS_DETAILS);
      //-----------filtres Par Role------------------------------//
      if (this.compte === "admin") {
        query = query.where("role.admin", "==", true);
      } else if (this.compte === "editor") {
        query = query.where("role.editor", "==", true);
      } else if (this.compte === "guest") {
        query = query.where("role.guest", "==", true);
      } else if (this.compte === "pro") {
        query = query.where("role.mercarueCustomerPro", "==", true);
      }

      //-----------filtres Nom + type de compte------------------------------//
      if (!FunctionsUtils.isNullOrEmptyOrSpaces(this.searchName)) {
        query = query
          .where(nodesFieldName.FIRST_NAME, ">=", this.searchName)
          .where(nodesFieldName.FIRST_NAME, "<=", this.searchName + "\uf8ff");
      } else if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.searchName) &&
        this.TypeCompte === "Particulier"
      ) {
        query = query
          .where(nodesFieldName.FIRST_NAME, ">=", this.searchName)
          .where(nodesFieldName.FIRST_NAME, "<=", this.searchName + "\uf8ff")
          .where(nodesFieldName.PROFESSIONAL, "==", false);
      } else if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.searchName) &&
        this.TypeCompte === "Professionnel"
      ) {
        query = query
          .where(nodesFieldName.FIRST_NAME, ">=", this.searchName)
          .where(nodesFieldName.FIRST_NAME, "<=", this.searchName + "\uf8ff")
          .where(nodesFieldName.PROFESSIONAL, "==", true);
      }
      //-----------filtres Prénom + type de compte------------------------------//
      if (!FunctionsUtils.isNullOrEmptyOrSpaces(this.searchSurname)) {
        query = query
          .where(nodesFieldName.LAST_NAME, ">=", this.searchSurname)
          .where(nodesFieldName.LAST_NAME, "<=", this.searchSurname + "\uf8ff");
      } else if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.searchSurname) &&
        this.TypeCompte === "Particulier"
      ) {
        query = query
          .where(nodesFieldName.LAST_NAME, ">=", this.searchSurname)
          .where(nodesFieldName.LAST_NAME, "<=", this.searchSurname + "\uf8ff")
          .where(nodesFieldName.PROFESSIONAL, "==", false);
      } else if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.searchSurname) &&
        this.TypeCompte === "Professionnel"
      ) {
        query = query
          .where(nodesFieldName.LAST_NAME, ">=", this.searchSurname)
          .where(nodesFieldName.LAST_NAME, "<=", this.searchSurname + "\uf8ff")
          .where(nodesFieldName.PROFESSIONAL, "==", true);
      }
      //-----------filtres  Nom d'utilisateur + type de compte------------------------------//
      if (!FunctionsUtils.isNullOrEmptyOrSpaces(this.searchUsername)) {
        query = query
          .where(nodesFieldName.USER_NAME, ">=", this.searchUsername)
          .where(
            nodesFieldName.USER_NAME,
            "<=",
            this.searchUsername + "\uf8ff"
          );
      } else if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.searchUsername) &&
        this.TypeCompte === "Particulier"
      ) {
        query = query
          .where(nodesFieldName.USER_NAME, ">=", this.searchUsername)
          .where(nodesFieldName.USER_NAME, "<=", this.searchUsername + "\uf8ff")
          .where(nodesFieldName.PROFESSIONAL, "==", false);
      } else if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.searchUsername) &&
        this.TypeCompte === "Professionnel"
      ) {
        query = query
          .where(nodesFieldName.USER_NAME, ">=", this.searchUsername)
          .where(nodesFieldName.USER_NAME, "<=", this.searchUsername + "\uf8ff")
          .where(nodesFieldName.PROFESSIONAL, "==", true);
      }

      //-----------filtres  date + type de compte------------------------------//
      if (this.dateRange === true) {
        query = query
          .where(nodesFieldName.CREATION_DATE, ">=", this.fromDate)
          .where(nodesFieldName.CREATION_DATE, "<=", this.toDate);
      } else if (this.TypeCompte === "Particulier" && this.dateRange === true) {
        query = query
          .where(nodesFieldName.PROFESSIONAL, "==", false)
          .where(nodesFieldName.CREATION_DATE, ">=", this.fromDate)
          .where(nodesFieldName.CREATION_DATE, "<=", this.toDate);
      } else if (
        this.TypeCompte === "Professionnel" &&
        this.dateRange === true
      ) {
        query = query
          .where(nodesFieldName.PROFESSIONAL, "==", true)
          .where(nodesFieldName.CREATION_DATE, ">=", this.fromDate)
          .where(nodesFieldName.CREATION_DATE, "<=", this.toDate);
      }
      //-----------filtres  date + type de compte------------------------------//
      if (this.TypeCompte === "Particulier") {
        query = query.where(nodesFieldName.PROFESSIONAL, "==", false);
      } else if (this.TypeCompte === "Professionnel") {
        query = query.where(nodesFieldName.PROFESSIONAL, "==", true);
      }

      var generalStats = await FirebaseDB.getGeneralStats();
      generalStats.forEach((stat) => {
        this.totalClientsCount = stat.data().number_total_users;
      });

      if (this.totalClients.length < this.totalClientsCount) {
        var querySnapshot = await FirebaseDB.getUsersListeWithPagination(
          query,
          this.lastVisibleClients,
          itemsPerPage,
          this.totalClientsCount
        );

        let i = 0;
        querySnapshot.forEach((client) => {
          this.totalClients.push({
            userId: client.data().userId,
            username: client.data().userName,
            name: client.data().firstName,
            surname: client.data().lastName,
            email: client.data().email,
            TypeDeCompte: client.data().professional,
            date: moment(
              new Date(client.data().creationDate.seconds * 1000)
            ).format("L HH:mm:ss"),
          });
          i++;
          if (querySnapshot.size == i) {
            this.lastVisibleClients = client;
          }
        });

        // Remove duplicates id in an array
        this.totalClients = this.totalClients.filter(
          ({ userId }, index) =>
            !this.totalClients.map((o) => o.userId).includes(userId, index + 1)
        );
      }

      this.clients = FunctionsUtils.customSortDataTable(
        this.totalClients,
        sortBy,
        sortDesc
      );

      this.clients = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalClients,
        itemsPerPage,
        page,
        this.totalClientsCount
      );

      this.loading = false;
    },
  },

  computed: {
    dateFormat() {
      return "MM/DD/YYYY";
    },
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    },
    headers() {
      return [
        { text: "ID", value: "userId" },
        { text: "Nom d'utilisateur ", value: "username" },
        { text: "Nom ", value: "name" },
        { text: "Prénom ", value: "surname" },
        { text: "Email", value: "email" },

        { text: "Type De Compte", value: "TypeDeCompte" },
        { text: "Date d'inscription", value: "date" },
        { text: "actions", value: "actions", sortable: false },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.loadClients();
      },
      deep: true,
    },
  },
};
</script>
